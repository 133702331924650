import React, { useEffect, useState } from "react";
import ControlDiv from "../../../components/Leaflet/ControlDiv";
import MainSidebar from "../../../components/Layout/MainSidebar";
import { ReactComponent as LogoRedemet } from "../../../assets/svg/logo-redemet.svg";
import SideBarSection from "../../../components/Layout/MainSidebar/SideBarSection";
import SidebarCollapsible from "../../../components/Layout/MainSidebar/SidebarCollapsible";
import { ReactComponent as WorldIcon } from "../../../assets/svg/icons/icon-world.svg";
import SidebarMenu from "../../../components/Layout/MainSidebar/SidebarMenu";
import SidebarMenuItem from "../../../components/Layout/MainSidebar/SidebarMenuItem";
import { ReactComponent as Grid3x3Icon } from "../../../assets/svg/icons/icon-grid-3x3.svg";
import { ReactComponent as OpenIcon } from "../../../assets/svg/icons/icon-open.svg";
import MainSidebarDivider from "../../../components/Layout/MainSidebar/SidebarDivider";
import MainSidebarSpacer from "../../../components/Layout/MainSidebar/SidebarSpacer";
import SidebarTrigger from "../../../components/Layout/MainSidebar/SidebarTrigger";
import MenuLayer from "../../../components/Leaflet/MenuLayer";
import Satelite from "../../../produtos/Satelite";
import Sigmet from "../../../produtos/Sigmet";
import { Status } from "../../../produtos/Aeroportos/Status";
import ICATiles from "../../../produtos/ICATiles";
import MenuModal from "../../../components/Material/MenuModal";
import MenuModalInterno from "../../../components/Material/MenuModalInterno";
import LogoATD from "../../../assets/images/logo_ATD_2x.png";
import LogoFAB from "../../../assets/images/fab-logo.png";
import LogoDECEA from "../../../assets/images/logo_DECEA_2x.png";
import LogoICEA from "../../../assets/images/logo_ICEA_2x.png";
import LogoCIMAER from "../../../assets/images/logo_CIMAER_2x.png";
import { LayerGroup } from "react-leaflet";
import Stsc from "../../../produtos/Tsc";
import Radares from "../../../produtos/Radares";
import SidebarCollapsibleChild from "../../../components/Layout/MainSidebar/SidebarCollapsibleChild";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { TSC, SATELITE, RADAR, SIGMET, AERODROMOS } from "../../../config";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Box } from "@material-ui/core";
import PesquisaSISCEAB2024 from "../../../assets/images/pesquisa-SISCEAB-2024.png";
import { CloseOutlined } from "@material-ui/icons";

type CollapsibleType = { [item: string]: boolean };

interface IMenuProps {
  triggerClass?: string;
}
const keyChecked = ["aerodromos", "tsc", "radar", "sigmet", "satelite"];

function Menu(props: IMenuProps) {
  const [collapsibleState, setCollapsible] = React.useState<CollapsibleType>({
    mapa: true,
    produtos: false,
    facilidades: false,
    sistemas: false,
  });

  const [checked, setChecked] = useState<string[]>(["aerodromos"]);
  const { search } = useLocation();

  const [showBanner, setShowBanner] = useState(true);

  const handleCloseBanner = () => {
    setShowBanner(false);
  }

  useEffect(() => {
    const query = queryString.parse(search, {
      arrayFormat: "separator",
    }) as any;
    if (query.layer) {
      if (typeof query.layer === "string") {
        setChecked([query.layer]);
      } else {
        const newChecked: string[] = [];
        query.layer.map((row) => {
          if (keyChecked.includes(row)) {
            newChecked.unshift(row);
          }
        });
        setChecked(newChecked);
      }
    }
  }, [search]);
  //  const [collapsibleOriginalState, setOriginalCollapsible] = React.useState<CollapsibleType>({});

  // const [filter, setFilter] = React.useState<string>('');

  // const [sidebarState, setSidebarState] = React.useState<boolean>(true);

  //const [autoatendimentoColapsibleState, setAutoCollapsible] = React.useState<boolean>(false)

  // function searchFilter(item: string) : boolean {
  //     // if(filter.length > 0) {
  //     //     setOriginalCollapsible(collapsibleState);
  //     //     toggleAllCollapsibles(true);
  //     // } else {
  //     //     setCollapsible(collapsibleOriginalState);
  //     // }
  //     // return item.indexOf(filter) > 0;
  //     return true;
  // }
  const toggleMenu = useSelector((state: RootState) =>
    state.menu == "close" ? false : true
  );

  /**
   * Checar se o produto foi chamado na url
   * @param value
   */
  const checkProdutoQuery = (value: string) => {
    return checked.includes(value);
  };

  return (
    <>
      <ControlDiv position="topleft">
        <SidebarTrigger className={props.triggerClass} />
      </ControlDiv>

      <ControlDiv position="bottomleft">
        <MainSidebar show={toggleMenu} logoSlot={<LogoRedemet />}>
          <SideBarSection>
            {/*<SidebarSearchFilter onChange={setFilter} />*/}
          </SideBarSection>
          <SideBarSection>
						{showBanner && (
              <Box sx={{
                position: 'absolute',
                bottom: '10px',
                left: 0,
                width: '100%',
                marginLeft: '260px',
              }}>
                <button onClick={handleCloseBanner}
                  style={{
                    background: '#333',
                    padding: '2px 1px 1px 1px',
                    borderRadius: '50%',
                    color: '#fff',
                    border: '0px',
                    fontSize: '2px',
                    position: 'absolute',
                    cursor: 'pointer',
										marginLeft: '5px',
										top: '-5px'
                }}>
                  <CloseOutlined />
                </button>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfQR-NsNvN-fcgXEL8Kbb0IPYaSCaSmbK-F6mwRA9jq4Y74xg/viewform"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{
                      borderRadius: '21px',
                      marginLeft: 10,
                      marginBottom: 5,
                    }}
                    src={PesquisaSISCEAB2024}
                    alt="Pesquisa SISCEAB 2024"
                  />
                </a>
              </Box>
            )}
            <SidebarCollapsible
              icon={<WorldIcon />}
              title="mapa"
              isOpen={collapsibleState["mapa"]}
              onTitleClick={(v) => {
                setCollapsible({ ...collapsibleState, mapa: v });
              }}
            >
              <SidebarMenu>
                <MenuLayer
                  name={AERODROMOS}
                  title={"Condições meteorológicas nos aeródromos"}
                  checked={checkProdutoQuery("aerodromos")}
                >
                  <LayerGroup>
                    <Status />
                  </LayerGroup>
                </MenuLayer>
                <MenuLayer
                  name={SATELITE}
                  title={"Imagens Satélite"}
                  checked={checkProdutoQuery("satelite")}
                >
                  <LayerGroup>
                    <Satelite />
                  </LayerGroup>
                </MenuLayer>
                <MenuLayer
                  name={RADAR}
                  title={"Imagens radar"}
                  checked={checkProdutoQuery("radar")}
                >
                  <LayerGroup>
                    <Radares />
                  </LayerGroup>
                </MenuLayer>
                <MenuLayer
                  name={SIGMET}
                  title={"Plotagem Mensagem SIGMET"}
                  checked={checked.includes("sigmet")}
                >
                  <LayerGroup>
                    <Sigmet />
                  </LayerGroup>
                </MenuLayer>
                <MenuLayer
                  name={TSC}
                  title={"Tempo Severo Convectivo"}
                  checked={checkProdutoQuery("tsc")}
                >
                  <LayerGroup>
                    <Stsc />
                  </LayerGroup>
                </MenuLayer>
                <SidebarCollapsibleChild
                  name="Plotagens"
                  title={"Camadas de Auxílio"}
                >
                  <ICATiles />
                </SidebarCollapsibleChild>
              </SidebarMenu>
            </SidebarCollapsible>
          </SideBarSection>
          <SideBarSection>
            <SidebarCollapsible
              icon={<Grid3x3Icon />}
              title="Produtos"
              isOpen={collapsibleState["produtos"]}
              onTitleClick={(v) => {
                setCollapsible({ ...collapsibleState, produtos: v });
              }}
            >
              <SidebarMenu>
                <MenuModal
                  menu="Cartas"
                  title="Cartas Meteorológicas"
									url="https://www.redemet.aer.mil.br/old/modal/cartas-meteorologicas/"
                />
                <MenuModal
                  menu="Mensagens"
                  title="Consulta Mensagens Meteorológicas"
                  url="https://www.redemet.aer.mil.br/old/modal/consulta-de-mensagens/"
                />
                <MenuModal
                  menu="Tabuleiro METAR"
                  title="Plotagem METAR em Tabuleiro"
                  url="https://www.redemet.aer.mil.br/old/modal/plotagem-metar/"
                />
                <MenuModal
                  menu="Modelagem"
                  title="Modelagem Numérica"
                  url="https://www.redemet.aer.mil.br/old/modal/modelagem-numerica/"
                />
                <MenuModal
                  menu="Satélite"
                  title="Imagens de Satélite"
                  url="https://www.redemet.aer.mil.br/old/modal/imagens-de-satelite/"
                />
                <MenuModal
                  menu="Radares"
                  title="Radares"
                  url="https://www.redemet.aer.mil.br/old/modal/radares-meteorologicos/?p=radares-meteorologicos"
                />
              </SidebarMenu>
            </SidebarCollapsible>
          </SideBarSection>
          <SideBarSection>
            <SidebarCollapsible
              icon={<OpenIcon />}
              title="Facilidades"
              isOpen={collapsibleState["facilidades"]}
              onTitleClick={(v) => {
                setCollapsible({ ...collapsibleState, facilidades: v });
              }}
            >
              <SidebarMenu>
                <MenuModal
                  menu="Artigos"
                  title="Artigos Meteorológicos"
                  url="https://www.redemet.aer.mil.br/old/modal/artigos/"
                />
                <MenuModal
                  menu="Centro Cinzas"
                  title="Centro Cinzas"
                  url="https://www.redemet.aer.mil.br/old/modal/centro-cinzas/"
                />
                <MenuModal
                  menu="Cobertura Volmet"
                  title="Cobertura Volmet"
                  url="https://www.redemet.aer.mil.br/old/modal/cobertura-volmet/"
                />
                <MenuModal
                  menu="Decode Metar"
                  title="Entendendo a decodificação do METAR"
                  url="https://www.redemet.aer.mil.br/old/modal/decode-metar/"
                />
                <MenuModal
                  menu="Glossário"
                  title="Glossário Meteorológico"
                  url="https://www.redemet.aer.mil.br/old/modal/glossario/"
                />
                <MenuModal
                  menu="Publicações de Meteorologia"
                  title="Publicações de Meteorologia"
                  url="https://publicacoes.decea.mil.br/?by=subject&value=Meteorologia"
                />
              </SidebarMenu>
            </SidebarCollapsible>
          </SideBarSection>
          {/*<SideBarSection>*/}
          {/*    <SidebarCollapsible*/}
          {/*        icon={<Grid2x2Icon />}*/}
          {/*        title="Sistemas"*/}
          {/*        isOpen={collapsibleState['sistemas']}*/}
          {/*        onTitleClick={(v) => {*/}
          {/*            setCollapsible({ ...collapsibleState, 'sistemas': v })*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        <SidebarMenu>*/}
          {/*            {*/}
          {/*                sistemasItems.filter(searchFilter).map(*/}
          {/*                    (item, index) => (*/}
          {/*                        <SidebarMenuItem key={index} onClick={(v) => alert(v)}>*/}
          {/*                            {item}*/}
          {/*                        </SidebarMenuItem>)*/}
          {/*                )*/}
          {/*            }*/}
          {/*        </SidebarMenu>*/}
          {/*    </SidebarCollapsible>*/}
          {/*</SideBarSection>*/}
          <MainSidebarDivider />
          {/*<SideBarSection>*/}
          {/*    <SidebarCollapsible*/}
          {/*        icon={<Grid2x2Icon />}*/}
          {/*        title="Autoatendimento"*/}
          {/*        isOpen={autoatendimentoColapsibleState}*/}
          {/*        onTitleClick={(v) => {*/}
          {/*            setAutoCollapsible(v)*/}
          {/*        }}*/}
          {/*    >*/}
          {/*    </SidebarCollapsible>*/}
          {/*</SideBarSection>*/}
          {/*<MainSidebarDivider />*/}
          <SideBarSection>
            <SidebarMenu>
              <SidebarMenuItem href="/monitoramento" target="_blank">
                MONITORAMENTO
              </SidebarMenuItem>
              <MenuModal
                menu="PLANEJE SEU VOO"
                title="Planeje seu voo"
                url="https://www.redemet.aer.mil.br/old/modal/autoatendimento/"
                linkExterno={true}
              />
              <SidebarMenuItem
                href="https://ajuda.decea.mil.br/base-de-conhecimento/api-redemet-o-que-e/"
                target="_blank"
              >
                API REDEMET
              </SidebarMenuItem>
              <SidebarMenuItem
                href="https://redemet-app.decea.mil.br/"
                target="_blank"
              >
                REDEMET LITE
              </SidebarMenuItem>
              <SidebarMenuItem
                href="https://forms.gle/hWCx5tfbuf93Czsx5"
                target="_blank"
              >
                Avalie nossos serviços
              </SidebarMenuItem>
              <SidebarMenuItem
                href="https://ajuda.decea.mil.br/artigo-categoria/redemet"
                target="_blank"
              >
                Central de Ajuda
              </SidebarMenuItem>
              <MenuModal
                menu="Fale Conosco"
                title="Fale Conosco"
                url="https://servicos.decea.mil.br/sac/?a=atd&c=331"
                linkExterno={true}
              />
              <MenuModalInterno
                menu="Termos de Uso"
                title="Termos de Uso"
                linkExterno={true}
              />
              <MenuModalInterno
                menu="Parceiros"
                title="Parceiros"
                linkExterno={true}
              />
              <MenuModalInterno menu="Links" title="Links" linkExterno={true} />
              <SidebarMenuItem
                href="https://pista.decea.mil.br/project/ximenesjx-redemet-5beta/issues"
                target="_blank"
              >
                Bugtrack
              </SidebarMenuItem>
              <SidebarMenuItem
                href="https://www.redemet.aer.mil.br/old/"
                target="_blank"
              >
                Redemet 4.0
              </SidebarMenuItem>
            </SidebarMenu>
          </SideBarSection>
          <MainSidebarSpacer />
          <SideBarSection>
            <p style={{ fontWeight: "bold" }}>Sobre a REDEMET</p>
            <p>
              A Rede de Meteorologia do Comando da Aeronáutica tem como objetivo
              integrar os produtos meteorológicos voltados à aviação civil e
              militar, visando tornar o acesso a estas informações mais rápido,
              eficiente e seguro.
            </p>
            <p>
              <a
                style={{ textDecoration: "none", color: "gray" }}
                href="https://fab.mil.br"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={LogoFAB}
                  alt="Logo FAB"
                  style={{ marginRight: "15px", paddingBottom: "3px" }}
                />
              </a>
              <a
                style={{ textDecoration: "none", color: "gray" }}
                href="https://www.decea.mil.br/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={LogoDECEA}
                  alt="Logo DECEA"
                  style={{ marginRight: "15px" }}
                />
              </a>
              <a
                style={{ textDecoration: "none", color: "gray" }}
                href="https://www2.fab.mil.br/cimaer/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={LogoCIMAER}
                  alt="Logo CIMAER"
                  style={{ marginRight: "15px" }}
                />
              </a>
              <a
                style={{ textDecoration: "none", color: "gray" }}
                href="http://pesquisa.icea.decea.mil.br/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={LogoICEA}
                  alt="Logo ICEA"
                  style={{ marginRight: "0px" }}
                />
              </a>
            </p>
          </SideBarSection>
          <SideBarSection>
            <p style={{ fontWeight: "bold" }}>Desenvolvido e mantido por:</p>
            <img src={LogoATD} alt="Logo ATD" style={{ marginLeft: "25px" }} />
          </SideBarSection>
        </MainSidebar>
      </ControlDiv>
    </>
  );
}

export default Menu;
