import { AxiosInstance, AxiosResponse } from "axios";
import Api from "../config/Api";

interface ISateliteDados {
  data: string;
  path: string;
  tamanho: number;
}

export interface IStscDados {
  cor: string;
  start: string;
  stop: string;
  min: number;
  pontos: [];
}

type layers = {
  url: string;
  layer: string;
};

export interface IRadarDados {
  id: number;
  nome: string;
  localidade: string;
  raio: number;
  lat_center: number;
  lon_center: number;
  lon_min: number;
  lon_max: number;
  lat_min: number;
  lat_max: number;
  path: string;
  tamanho: number;
  data: string;
}

export const SateliteInitial = {
  tipo: "realcada",
  lat_lon: {
    id: 0,
    lon_min: 0,
    lon_max: 0,
    lat_min: 0,
    lat_max: 0,
  },
  anima: [],
  satelite: [],
};

export const RadarInitial = {
  tipo: "",
  anima: [],
  radar: [],
};

export const StscInitial = {
  anima: [],
  stsc: [],
};

export const WmsInitial = [
  {
    menu: "",
    alt: "",
    layers: [],
  },
];

export interface ISatelite {
  tipo: string;
  lat_lon: {
    id: number;
    lon_min: number;
    lon_max: number;
    lat_min: number;
    lat_max: number;
  };
  anima: string[];
  satelite: ISateliteDados[];
}

export interface IWms {
  menu: string;
  alt: string;
  layers: layers[];
}

export interface IStsc {
  stsc: IStscDados[];
}

export interface IRadar {
  tipo: string;
  anima: string[];
  radar: IRadarDados[];
}

export class Produtos {
  constructor(private http: AxiosInstance, protected resource: string) {}

  satelite<T = any>(tipo: string, anima = 15): Promise<AxiosResponse<T>> {
    return this.http.get<T>(`${this.resource}/satelite/${tipo}?anima=${anima}`);
  }

  stsc<T = any>(anima = 0): Promise<AxiosResponse<T>> {
    return this.http.get<T>(`${this.resource}/stsc/${anima}`);
  }

  radar<T = any>(tipo: string, anima = 8): Promise<AxiosResponse<T>> {
    return this.http.get<T>(`${this.resource}/radar/${tipo}?anima=${anima}`);
  }

  wms<T = any>(): Promise<AxiosResponse<T>> {
    return this.http.get<T>(`${this.resource}/wms/`);
  }
}

const produtosRepositories = new Produtos(Api, "produtos");
export default produtosRepositories;
