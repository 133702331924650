import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Circle, Polygon, Popup } from "react-leaflet";
import { IPaginator, IResponse } from "../../repositories/models";
import mensagensRepositories, { ISigmet } from "../../repositories/Mensagens";
import LayerControlContainer from "../../components/LayerControl/LayerControlContainer";
import ControlDiv from "../../components/Leaflet/ControlDiv";
import LineLegend from "../../components/LayerControl/LineLegend";
import useLayerChecked from "../../hooks/useLayerChecked";
import { SIGMET } from "../../config";
import useLayerUpdated from "../../hooks/useLayerUpdated";
import AtualizacaoItem from "../../components/LayerControl/AtualizacaoItem";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { dateUtc } from "../../utils/dates";
import { Button } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useSnackbar } from "notistack";

const columnsLegend = ["Fenômeno", "Descrição", "Cor"];
const dataLegend = [
  ["VA CLD", "Cinzas Vulcânicas", "FF7F50"],
  ["RDOACT CLD", "Nuvem Radioativa", "CEECF5"],
  ["ICE", "Gelo", "00BFFF"],
  ["TC", "Ciclone", "000000"],
  ["TS", "Trovoada", "FF0000"],
  ["TURB", "Turbulência", "FFFF00"],
  ["VA", "Vulcão", "5C2E00"],
];

function decodificaRaio(value: string) {
  const expNM = new RegExp("[0-90-90-9]+NM");
  const expKM = new RegExp("[0-90-90-9]+KM");
  let raio;
  let distancia;
  if (expNM.test(value)) {
    const myArray = expNM.exec(value);
    if (myArray) {
      distancia = myArray[0];
      const nm = parseInt(distancia.replace("NM", ""));
      raio = nm * 1852;
      return raio;
    }
  } else if (expKM.test(value)) {
    const myArray = expKM.exec(value);
    if (myArray) {
      distancia = myArray[0];
      const nm = parseInt(distancia.replace("KM", ""));
      raio = nm * 1000;
      return raio;
    }
  } else {
    return 0;
  }
}
const layer = SIGMET;

interface SigmetType extends React.ComponentProps<any> {
  active?: boolean;
}

const Sigmet: FC<SigmetType> = ({ active = false, ...props }) => {
  const snackbar = useSnackbar();
  const subscribed = useRef(true);
  const [data, setData] = useState<ISigmet[]>([]);
  const [control, setControl] = React.useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { checkedLayer } = useLayerChecked({
    layer,
  });
  const layerRedux = useSelector(
    (state: RootState) => state.layers.data[layer as any]
  );

  const getSigmet = useCallback(async () => {
    setData([]);
    setLoading(true);
    try {
      const response = await mensagensRepositories.sigmet<
        IResponse<IPaginator<ISigmet>>
      >();
      if (subscribed.current) {
        setData(response.data.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useLayerUpdated({
    onLoad: getSigmet,
    seconds: 300,
    checkedLayer: checkedLayer || active,
    layer,
  });

  const copyClipboard = useCallback(
    (text) => {
      navigator.clipboard.writeText(text);
      snackbar.enqueueSnackbar("Copiado com sucesso", {
        variant: "success",
        autoHideDuration: 1000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    },
    [snackbar]
  );

  const SigmetMap = useMemo(() => {
    return data.map((obj, index) => {
      if (obj.fenomeno !== "TC") {
        return (
          <Polygon key={index} color={obj.fenomeno_cor} positions={obj.lat_lon}>
            <Popup>
              <Typography variant="button" display="block" gutterBottom>
                {obj.title}
              </Typography>
              <Typography variant="body2" display="block" gutterBottom>
                {obj.fenomeno_comp}
              </Typography>
              <Tooltip title={obj.mens} aria-label="mens">
                <Button
                  onClick={() => copyClipboard(obj.mens)}
                  color={"primary"}
                  size={"small"}
                >
                  Copiar Mensagem
                </Button>
              </Tooltip>
            </Popup>
          </Polygon>
        );
      }
    }) as any;
  }, [data, copyClipboard]);

  const CicloneMap = useMemo(() => {
    return data.map((obj, index) => {
      if (obj.fenomeno === "TC") {
        const raio = decodificaRaio(obj.mens);
        return (
          <Circle
            key={index}
            color={"#000000"}
            center={obj.lat_lon[0]}
            radius={raio}
          >
            <Popup>
              <Typography variant="button" display="block" gutterBottom>
                {obj.title}
              </Typography>
              <Typography variant="body2" display="block" gutterBottom>
                {obj.fenomeno_comp}
              </Typography>
              <Tooltip title={obj.mens} aria-label="mens">
                <Button
                  onClick={() => copyClipboard(obj.mens)}
                  color={"primary"}
                  size={"small"}
                >
                  Copiar Mensagem
                </Button>
              </Tooltip>
            </Popup>
          </Circle>
        );
      }
    }) as any;
  }, [data, copyClipboard]);

  if (checkedLayer || active) {
    return (
      <>
        {SigmetMap}
        {CicloneMap}
        <ControlDiv position={"topright"}>
          <div {...props}>
            <LayerControlContainer
              title={"Sigmet"}
              titleSuffix={`${dateUtc(new Date()).substring(0, 2)}Z`}
              isActive={control}
              toggle={setControl}
              loading={loading}
            >
              <AtualizacaoItem
                layer={layer}
                contador={layerRedux?.time}
                data={layerRedux?.lastUpdate}
              />
              <LineLegend columns={columnsLegend} data={dataLegend} />
            </LayerControlContainer>
          </div>
        </ControlDiv>
      </>
    );
  } else {
    return null;
  }
};

export default Sigmet;
